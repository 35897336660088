import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Card from './Card';
import DOMPurify from 'dompurify';

const Skeleton = () => (
  <div className="animate-pulse flex flex-col md:flex-row justify-center ml-[3%] mr-[3%] md:ml-[10%] md:mr-[10%] gap-2">
    <div className="w-full md:w-[50%] h-[60vw] md:h-[25vw] bg-gray-300 rounded-md"></div>
    <div className="w-full md:w-[50%] bg-[#303030] rounded-md p-6 text-white">
      <div className="h-6 bg-gray-300 rounded mb-4"></div>
      <div className="h-4 bg-gray-300 rounded mb-2"></div>
      <div className="h-4 bg-gray-300 rounded mb-2"></div>
      <div className="h-4 bg-gray-300 rounded mb-2"></div>
      <div className="h-4 bg-gray-300 rounded mb-6"></div>
      <div className="flex justify-end gap-4">
        <div className="h-8 w-20 bg-gray-300 rounded"></div>
        <div className="h-8 w-20 bg-gray-300 rounded"></div>
      </div>
    </div>
  </div>
);

export default function Projects() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [modalImageIndex, setModalImageIndex] = useState(null); // Index pour modale
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`https://portfolio.elhakimi.com/api/projects/${id}`);
        if (!response.data || Object.keys(response.data).length === 0) {
          navigate('/projects');
        } else {
          setProject(response.data);
        }
      } catch (err) {
        setError(err.message);
        navigate('/projects');
      } finally {
        setLoading(false);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://portfolio.elhakimi.com/api/projects');
        setProjects(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchProject();
    fetchProjects();

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [id, navigate]);

  const isMobile = window.innerWidth <= 768; // Vérifie si l'utilisateur est sur un appareil mobile

  const openModal = (index) => {
    if (!isMobile) {
      // Désactive l'ouverture de la modale sur mobile
      setModalImageIndex(index);
    }
  };

  const closeModal = () => {
    setModalImageIndex(null); // Ferme la modale
  };

  const showNextImage = () => {
    setModalImageIndex((prevIndex) => (prevIndex + 1) % project.images.length);
  };

  const showPreviousImage = () => {
    setModalImageIndex((prevIndex) => (prevIndex - 1 + project.images.length) % project.images.length);
  };

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const safeDescription = DOMPurify.sanitize(project.description);

  const filteredProjects = projects.filter((p) => p.id !== project.id);

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-center ml-[3%] mr-[3%] md:ml-[10%] md:mr-[10%] gap-2">
        <div className="w-full md:w-[50%] relative">
          <Slider {...sliderSettings}>
            {project.images && project.images.length > 0 ? (
              project.images.map((image, index) => (
                <div
                  key={index}
                  className="w-full flex items-center justify-center bg-gray-800 rounded-lg"
                  onClick={() => openModal(index)} // Ouvre la modale uniquement sur ordinateur
                >
                  <div className="relative w-full" style={{ aspectRatio: '16 / 9' }}>
                    <img
                      src={image.url}
                      alt={`Project Image ${index}`}
                      className="object-cover w-full h-full absolute inset-0 rounded-lg"
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-white">No images available</div>
            )}
          </Slider>
        </div>

        <div className="w-full md:w-[50%] bg-[#303030] rounded-md p-6 text-white">
          <h1 className="text-2xl font-bold text-[#E5F15E] mb-4">{project.title}</h1>
          <div
            className="text-base leading-relaxed mb-6"
            dangerouslySetInnerHTML={{ __html: safeDescription }}
          />
          <div className="flex justify-end gap-4">
            {project.github && (
              <button
                className="bg-transparent hover:bg-blue-500 text-white font-semibold hover:text-white py-2 px-4 border border-white hover:border-transparent rounded"
                onClick={() => window.open(project.github, '_blank')}
              >
                Github
              </button>
            )}
            {project.demo && (
              <button
                className="bg-transparent hover:bg-blue-500 text-white font-semibold hover:text-white py-2 px-4 border border-white hover:border-transparent rounded"
                onClick={() => window.open(project.demo, '_blank')}
              >
                Demo
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Modale d'agrandissement des images */}
      {modalImageIndex !== null && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50"
          onClick={closeModal} // Ferme la modale en cliquant à l'extérieur
        >
          <div
            className="relative flex items-center justify-center max-w-[70%] max-h-[70%]"
            onClick={(e) => e.stopPropagation()} // Empêche la fermeture en cliquant sur l'image ou les boutons
          >
            {/* Image affichée */}
            <img
              src={project.images[modalImageIndex].url}
              alt="Modal View"
              className="w-full h-full object-cover rounded-lg"
            />

            {/* Boutons de navigation placés en dehors de l'image */}
            <button
              className="absolute left-[-50px] top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-3 py-2 rounded-full hover:bg-gray-900"
              onClick={(e) => {
                e.stopPropagation();
                showPreviousImage();
              }}
            >
              ◀
            </button>
            <button
              className="absolute right-[-50px] top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-3 py-2 rounded-full hover:bg-gray-900"
              onClick={(e) => {
                e.stopPropagation();
                showNextImage();
              }}
            >
              ▶
            </button>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center justify-center mt-10">
        <h2 className="text-3xl font-bold mb-6 text-white">All Projects</h2>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4 md:mx-4 md:my-4 mx-0 my-0 md:mr-[8vw] md:ml-[8vw]">
          {filteredProjects.map((project) => (
            <Card
              key={project.id}
              id={project.id}
              images={project.images}
              title={project.title}
              description={project.description}
              githubLink={project.github}
              demoLink={project.demo}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
