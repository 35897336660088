import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Slider from 'react-slick';

// Ensure you import the required CSS for react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Card = ({ id, images, title, description, githubLink, demoLink }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(null); // Index de l'image affichée dans la modale
  const navigate = useNavigate();

  const isMobile = window.innerWidth <= 768; // Vérifie si l'écran est mobile

  const handleGitHubClick = (e) => {
    e.stopPropagation();
    window.open(githubLink, '_blank');
  };

  const handleDemoClick = (e) => {
    e.stopPropagation();
    window.open(demoLink, '_blank');
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const openModal = (index) => {
    if (!isMobile) {
      // N'ouvre pas la modale si l'écran est mobile
      setModalImageIndex(index);
    }
  };

  const closeModal = () => {
    setModalImageIndex(null);
  };

  const showNextImage = () => {
    setModalImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const showPreviousImage = () => {
    setModalImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="flex flex-col justify-center m-6 p-2 bg-[#303030] rounded-lg hover:bg-[#2d2d2d] shadow-black shadow-lg">
      <div className="relative mb-3">
        <Slider {...sliderSettings}>
          {images.map((image, index) => (
            <div key={index} className="relative w-full h-52">
              <img
                src={image.url}
                alt={`Slide ${index}`}
                className="w-full h-full object-cover rounded-lg cursor-pointer"
                onClick={() => openModal(index)} // Ouvre la modale uniquement si l'écran n'est pas mobile
              />
            </div>
          ))}
        </Slider>
      </div>
      <Link
        title='Show project details'
        to={`/projects/${id}`}
        onClick={scrollToTop}
        className="p-3 block"
      >
        <h2 className="text-[#E5F15E] text-2xl">{title}</h2>
        <div
          className={`text-gray-300 mt-2 ${
            !showFullDescription ? 'description-limited' : ''
          }`}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {!showFullDescription && description.length > 100 && (
          <span
            className="show-more"
            onClick={() => setShowFullDescription(true)}
          >
          </span>
        )}
      </Link>
      <div className="flex mt-4 justify-end">
        {githubLink && (
          <button
            onClick={handleGitHubClick}
            className="bg-transparent hover:bg-[#000] text-[#FFF] font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded mr-2"
            aria-label="View GitHub repository"
          >
            GitHub
          </button>
        )}
        {demoLink && (
          <button
            onClick={handleDemoClick}
            className="bg-transparent hover:bg-blue-500 text-[#FFF] font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded"
            aria-label="View demo"
          >
            Demo
          </button>
        )}
      </div>
      {modalImageIndex !== null && (
  <div
    className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50"
    onClick={closeModal} // Fermer la modale en cliquant à l'extérieur
  >
    <div
      className="relative max-w-[70%] max-h-[70%] flex items-center justify-center"
      onClick={(e) => e.stopPropagation()} // Empêche la fermeture en cliquant sur l'image ou les boutons
    >
      {/* Image affichée */}
      <img
        src={images[modalImageIndex].url}
        alt="Modal View"
        className="w-full h-full object-cover rounded-lg"
      />

      {/* Bouton gauche - positionné en dehors de l'image */}
      <button
        className="absolute left-[-80px] top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-4 py-3 rounded-full hover:bg-gray-900 shadow-lg"
        onClick={(e) => {
          e.stopPropagation();
          showPreviousImage();
        }}
      >
        ◀
      </button>

      {/* Bouton droit - positionné en dehors de l'image */}
      <button
        className="absolute right-[-80px] top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-4 py-3 rounded-full hover:bg-gray-900 shadow-lg"
        onClick={(e) => {
          e.stopPropagation();
          showNextImage();
        }}
      >
        ▶
      </button>
    </div>
  </div>
)}

    </div>
  );
};

export default Card;
